<template>
  <div class="w-full pt-5 pb-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="
          $router.back()
        "
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />

      <div style="width: 220px">
        <h1 class="text-xl text-left font-extrabold ml-4 mt-3">
          New Agreement
        </h1>
      </div>

      <div class="w-7/12 mt-4">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <div style="height: 100%" class="mt-20" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>

    <div v-else>
      <ValidationObserver rules="required" v-slot="{ handleSubmit }">
        <div class="px-3">
          <card class="p-5 mt-3">
            <p class="text-lg font-bold">Agreement Details</p>
            <div class="grid grid-cols-12 gap-7" style="margin-top: 1.125rem">
              <div class="col-span-4 mt-2">
                <div class="text-lg text-jet -mb-1">Financial Year</div>
                <div
                  class="date-flex mt-2"
                  :style="periodError ? 'border : 1px solid red' : ''"
                >
                  <datepicker
                    placeholder="--Select Day & Month & Year--"
                    style="width: 100%; outline: none"
                    input-class="date-input"
                    format="yyyy"
                    minimum-view="year"
                    @selected="periodSelect"
                    v-model="payload.year"
                  />
                </div>
                <small
                  data-v-d5a084b0=""
                  class="mt-1 text-red-700 text-xs font-semibold"
                  v-if="periodError"
                  >Financial year is not valid.</small
                >
              </div>

              <div class="col-span-4">
                <c-text
                  placeholder="--Enter--"
                  variant="w-full"
                  class="mt-2"
                  v-model="payload.title"
                  label="Description"
                  :rules="['required']"
                  :disabled="true"
                />
              </div>

              <div class="col-span-4">
                <c-select
                  label="Template"
                  placeholder="Select Template"
                  variant="w-full"
                  class="mt-2"
                  :options="templateOptions"
                  v-model="selectedTemplate"
                  :rules="['required']"
                />
              </div>
            </div>

            <div
              class="w-full p-2 h-auto flex flex-col mt-5 cycle"
              v-if="selectedTemplate !== ''"
            >
              <div class="w-11/12 flex cycle__text">CYCLE DETAILS</div>

              <card class="w-full fcard">
                <div class="w-full flex h-auto">
                  <div class="fcard__icon">
                    <icon :icon-name="doccycle" size="csm" />
                  </div>
                  <div class="w-full flex flex-col h-auto">
                    <div class="fcard__header" style="color: #490166">
                      {{ cycleTitle }}
                    </div>
                    <div class="w-full fcard__text">
                      {{ cycleDescription }}
                    </div>
                  </div>
                </div>
              </card>

              <div class="w-full h-auto flex flex-wrap">
                <card class="w-5/12 flex flex-grow mr-3 fcard">
                  <div class="w-full flex h-auto">
                    <div class="fcard__icon">
                      <icon :icon-name="calendarIcon" size="csm" />
                    </div>
                    <div class="w-full flex flex-col h-auto">
                      <div class="fcard__header" style="color: #e99323">
                        Cycle Period
                      </div>
                      <div class="w-full fcard__text">
                        {{ $DATEFORMAT(new Date(cycleStartPeriod), "MMMM dd") }}
                        -
                        {{ $DATEFORMAT(new Date(cycleEndPeriod), "MMMM dd") }}
                      </div>
                    </div>
                  </div>
                </card>

                <card
                  class="w-5/12 flex flex-grow mb-3 fcard"
                  v-for="(cycles, index) in appraisal_cycles"
                  :key="index"
                >
                  <div class="w-full flex h-auto">
                    <div class="fcard__icon">
                      <icon :icon-name="reviewIcon" size="csm" />
                    </div>
                    <div class="w-full flex flex-col h-auto">
                      <div class="fcard__header" style="color: #2176ff">
                        {{ cycles.name }}
                      </div>
                      <div class="w-full fcard__text">
                        {{
                          $DATEFORMAT(
                            new Date(cycles.appraisal_starts),
                            "MMMM dd"
                          )
                        }}
                        -
                        {{
                          $DATEFORMAT(
                            new Date(cycles.appraisal_ends),
                            "MMMM dd"
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </card>
              </div>
            </div>

            <div
              class="w-full p-2 h-auto flex flex-col mt-5"
              style="border: 1px dashed #878e99; border-radius: 5px"
              v-else
            >
              <div class="w-11/12 h-6 p-1 flex cyc-text">
                PERFORMANCE DETAILS
              </div>
              <div class="w-11/12 h-6 p-1 mt-4 mb-3 inner-text">
                --Select a Performance Template to view Appraisal Performance
                Details here---
              </div>
            </div>

            <div
              class="w-full p-2 h-auto flex flex-col mt-5"
              style="border: 1px dashed #878e99; border-radius: 5px"
            >
              <div class="w-full flex">
                <div class="w-auto h-6 p-1 flex flex-grow cyc-text uppercase">
                  Create For
                </div>
                <div
                  class="text-flame font-semibold flex mb-1 p-2 text-base cursor-pointer"
                  @click="addEmployees()"
                >
                  <icon icon-name="icon-plus" class="mr-2" size="xs" />
                  Employees
                </div>
              </div>
              <div class="w-full p-1 mt-4 mb-3 inner-text">
                <div class="w-full flex flex-wrap">
                  <div
                    class="mb-3 w-1/3 flex border p-3 mr-2"
                    style="border-radius: 5px; width: 32.6%"
                    v-for="(item, index) in selectedEmployees"
                    :key="index"
                  >
                    <div class="w-full flex">
                      <div class="flex flex-grow">
                        <div class="flex">
                          <img
                            class="mr-3"
                            :src="item.photo"
                            v-if="item.photo"
                            alt="user photo"
                            style="
                              height: 30px;
                              width: 30px;
                              border-radius: 5px;
                            "
                          />
                          <div
                            style="
                              height: 30px;
                              width: 30px;
                              border-radius: 5px;
                            "
                            class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold"
                            v-else
                          >
                            {{ $getInitials(`${item.fname} ${item.lname}`) }}
                          </div>
                        </div>
                        <div class="flex flex-col flex-grow">
                          <p class="flex -mt-1">
                            {{ `${item.fname} ${item.lname}` }}
                          </p>
                          <div
                            class="text-xs flex -mt-1 font-semibold w- text-romanSilver uppercase"
                          >
                            <p class="opacity-50 mr-2" style="font-size: 10px">
                              {{
                                `${
                                  item.userDesignation
                                    ? item.userDesignation
                                    : "---"
                                }`
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="flex">
                        <span
                          class="flex cursor-pointer"
                          @click="viewEmployeeDetails(item.id)"
                        >
                          <Badge
                            label="Details"
                            variant="primary"
                            background-color="rgba(33, 118, 255, 0.08)"
                            color="#2176FF"
                            class="capitalize"
                            style="
                              margin-right: 12px;
                              font-size: 14px;
                              padding: 5px;
                              height: 25px;
                              margin-top: 0.5rem;
                              border-radius: 5px;
                            "
                          />
                        </span>
                        <span
                          class="flex cursor-pointer"
                          @click="removeEmployee(index, item)"
                        >
                          <icon
                            icon-name="close"
                            class-name="text-darkPurple cursor-pointer"
                            size="xs"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </card>
        </div>
        <div class="!w-[max-content] ml-auto mr-10 py-5 importFile"
        >
          <Button
          v-if="payload.templateId !== ''"
          class="mt-5 text-white bg-dynamicBackBtn !w-[max-content]"
          style="height: 2.75rem;width:max-content; margin-left:auto"
          @click="impExpOptSelected()"
        >
        Import from File
        </Button>
        </div>

        <div class="px-3">
          <card class="p-5" style="margin-top: 4px">
            <div
              class="w-full flex flex-row"
              style="margin-top: 20px; height: 44px; flex-grow: 1"
            >
              <div class="w-9/12 h-auto mt-2">
                <h1 class="text-base font-bold">KPAs</h1>
              </div>

              <div
                class="h-full w-auto flex flex-row"
                v-if="selectedTemplate !== ''"
              >
                <div
                  class="p-2 mx-3 bg-ghostWhite h-full flex"
                  style="border-radius: 5px"
                >
                  <span class="text-sm font-black flex flex-grow">
                    Total Allocated Weight:
                  </span>
                  <span class="text-sm text-desire ml-3">
                    {{ allocatedWeight }}%
                  </span>
                  <icon :icon-name="iconInfo" size="ksm" />
                </div>
              </div>
            </div>

            <div style="border-bottom: 1px solid #878e99; height: 1rem" />

            <div
              class="w-full h-auto my-6 flex flex-col"
              style="align-items: center"
              v-if="templateKPATypes.length === 0"
            >
              <div
                class="w-5/12 h-auto mb-4 mt-4 flex"
                style="justify-content: center"
              >
                <icon
                  :icon-name="illustration"
                  size="auto"
                  class-name="icon-Size mb-2"
                />
              </div>
              <div class="w-6/12 h-auto illu-text">
                You set organizational goals and objectives here. KPAs appear
                here when you select a template. You can edit selected template
                based on permissions assigned. Select a template to get started.
              </div>
            </div>

            <div class="flex flex-col w-full my-4" v-else>
              <!----- List of KPA's ---->

              <div class="w-full h-auto flex flex-col">
                <accordion
                  class="mt-6"
                  v-for="(kpaTypes, kpaNumber) in templateKPATypes"
                  :key="kpaNumber"
                  show-divider
                  @open="handleOpen($event, kpaTypes.id)"
                  :is-open="true"
                >
                  <template v-slot:title>
                    <span class="font-bold">{{ kpaTypes.name }}</span>
                  </template>

                  <template v-slot:block>
                    <div
                      class="p-2 w-auto flex gap-2 items-center bg-ghostWhite rounded-sm mr-2"
                    >
                      <span
                        class="font-black text-xs"
                        :class="
                          getKPAWeight(kpaTypes, kpaTypes.id) >
                          getTotalWeight(kpaTypes, kpaTypes.id)
                            ? ''
                            : ''
                        "
                      >
                        {{ getKPAWeight(kpaTypes, kpaTypes.id) }}%
                      </span>
                    </div>
                  </template>

                  <div class="w-full">
                    <ValidationObserver v-slot="{ handleSubmit }">
                      <div class="w-full flex justify-end">
                        <div
                          class="text-flame font-semibold flex mt-3 mb-1 p-2 text-base cursor-pointer"
                          @click="handleSubmit(createNewKPA(kpaTypes))"
                        >
                          <icon icon-name="icon-plus" class="mr-2" size="xsm" />
                          Add New KPI
                        </div>
                      </div>

                      <scroll-container height="1000px">
                        <Table
                          :headers="KPAHeader"
                          :items="kpaTypes.kpas"
                          class="h-full my-2 table"
                          aria-label="absence table"
                          :has-number="false"
                        >
                          <template v-slot:item="{ item }">
                            <div v-if="item.state" class="-ml-3">
                              <div v-if="item.data.state" class="p-2">
                                <div class="flex flex-col">
                                  <div
                                    class="cursor-pointer"
                                    v-if="item.data.isLocked"
                                    @click="
                                      lockKPA(item.data.isLocked, item.data)
                                    "
                                  >
                                    <icon
                                      icon-name="icon-lock"
                                      size="xs"
                                      class="my-2"
                                    />
                                  </div>
                                  <div
                                    class="cursor-pointer"
                                    v-else
                                    @click="
                                      lockKPA(item.data.isLocked, item.data)
                                    "
                                  >
                                    <icon
                                      icon-name="icon-unlock"
                                      size="xs"
                                      class="my-2"
                                    />
                                  </div>
                                  <div
                                    class="cursor-pointer"
                                    @click="deleteKPA(item.index, kpaTypes)"
                                    v-if="!item.data.isLocked"
                                  >
                                    <icon
                                      icon-name="icon-trash"
                                      size="xs"
                                      class="my-2"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div v-else-if="item.name">
                              <div class="label">
                                <c-text
                                  placeholder="--Enter KPI--"
                                  variant="w-full"
                                  class="mr-2 -ml-1 -mt-1 w-72"
                                  :disabled="item.data.isLocked"
                                  v-tooltip.bottom-middle="item.data.name"
                                  v-model="item.data.name"
                                  :rules="['required']"
                                  label="KPI"
                                />
                              </div>
                            </div>

                            <div v-else-if="item.objective">
                              <div class="label">
                                <c-text
                                  placeholder="--Enter Objective--"
                                  variant="w-full"
                                  class="mr-2 -ml-1 -mt-2 w-72"
                                  :disabled="item.data.isLocked"
                                  v-model="item.data.objective"
                                  v-tooltip.bottom-middle="item.data.objective"
                                  :rules="['required']"
                                  label="Objective"
                                />
                              </div>
                            </div>

                            <div v-else-if="item.kpis" class="flex">
                              <div
                                class="label flex"
                                v-for="(n, rating) in item.data.kpis"
                                :key="rating"
                              >
                                <ValidationObserver>
                                  <c-text
                                    variant="w-full"
                                    :class="`mr-2 -mt-2 w-48`"
                                    :placeholder="`Criteria ${rating}`"
                                    :disabled="item.data.isLocked"
                                    v-model="item.data.kpis[rating].criteria"
                                    v-tooltip.bottom-middle="
                                      item.data.kpis[rating].criteria
                                    "
                                    :rules="['required']"
                                    :label="`Criteria ${rating} value`"
                                  />
                                </ValidationObserver>
                              </div>
                            </div>

                            <div v-else-if="item.target_date" class="-mt-2">
                              <div class="label w-72">
                                <label class="text-sm">Target date <span data-v-d5a084b0="" class="text-base font-bold text-razzmataz">*</span></label>
                                <div
                                  class="date-flex"
                                  :class="
                                    item.data.isLocked ? 'bg-disabled' : ''
                                  "
                                >
                                  <datepicker
                                    placeholder="--Select Day & Month & Year--"
                                    style="width: 100%; outline: none"
                                    input-class="date-input"
                                    format="yyyy-MMMM-dd"
                                    v-model="item.data.target_date"
                                    v-tooltip.bottom-middle="
                                      item.data.target_date
                                    "
                                    :disabled="item.data.isLocked"
                                  />
                                </div>
                              </div>
                            </div>

                            <div v-else-if="item.weight">
                              <div class="label">
                                <c-text
                                  placeholder="--Enter Weight--"
                                  variant="w-full"
                                  class="mr-2 -ml-1 -mt-2 w-24"
                                  type="number"
                                  min="0"
                                  max="100"
                                  :rules="weightRules"
                                  v-model="item.data.weight"
                                  v-tooltip.bottom-middle="item.data.weight"
                                  :disabled="item.data.isLocked"
                                  label="Weight"
                                />
                              </div>
                            </div>
                          </template>
                        </Table>
                      </scroll-container>
                    </ValidationObserver>
                  </div>
                </accordion>
              </div>
            </div>
          </card>
        </div>

        <div class="pl-3 flex mt-5">
          <div class="flex flex-grow">
            <Button
            :disabled="disableBtn || selectedEmployees.length === 0 || allocatedWeight < 100"
              :class="
                disableBtn ? `btn-disabled` : `bg-dynamicBackBtn text-white`
              "
              width="auto"
              @click="handleSubmit(submit)"
            >
              <span class="mr-6 ml-6">Share with employee(s)</span>
            </Button>

            <Button
              background-color="none"
              class="ml-4"
              style="color: #321c3b"
              @click="$router.back()"
            >
              Cancel
            </Button>
          </div>
          <div class="flex justify-end mr-5">
            <Button
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn text-center"
              width="8rem"
              @click="submit('draft')"
              :disabled="disableBtn || selectedEmployees.length === 0"
              v-if="payload.status === 'draft' && payload.title !== ''"
            >
              Save as Draft
            </Button>
          </div>
        </div>
      </ValidationObserver>

      <RightSideBar
        v-if="addEmployeesModal"
        @close="addEmployeesModal = false"
        submit="Proceed"
        @submit="employeeSubmit()"
        :button-class="`bg-dynamicBackBtn text-white`"
      >
        <template v-slot:title>
          <div class="w-full flex">
            <p class="text-darkPurple mt-3">Add Employees</p>
          </div>
        </template>
        <template v-slot:subtitle>
          <div class="flex flex-col w-full mb-3">
            <p class="my-2 text-base">
              Add employees for whom you are creating a performance agreement
              for.
            </p>
            <p>
              Please note that by sharing, this agreement will override all
              previously shared agreements.
            </p>
          </div>
        </template>

        <search-input class="my-4" @input="startSearch" placeholder="Search" />

        <div class="border border-dashed my-4 w-full" />

        <div style="height: 100%" v-if="loadingBar">
          <loader size="xxs" :loader-image="false" />
        </div>
        <div v-else>
          <div
            v-for="(item, index) in employees"
            :key="index"
            class="flex flex-col py-4 userContainer border-b border-dashed"
          >
            <div class="flex items-center">
              <div>
                <img
                  class="mr-3"
                  :src="item.photo"
                  v-if="item.photo"
                  alt="user photo"
                  style="height: 35px; width: 35px; border-radius: 5px"
                />
                <div
                  style="height: 35px; width: 35px; border-radius: 5px"
                  class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                  v-else
                >
                  {{ $getInitials(`${item.fname} ${item.lname}`) }}
                </div>
              </div>
              <div class="flex flex-col flex-grow">
                <p>
                  {{ `${item.fname} ${item.lname}` }}
                </p>
                <div
                  class="text-xs flex font-semibold w- text-romanSilver uppercase"
                >
                  <p class="opacity-50 mr-2" style="font-size: 10px">
                    {{
                      `${item.userDesignation ? item.userDesignation : "---"}`
                    }}
                  </p>
                  <p class="opacity-50 ml-1 mr-2" style="font-size: 10px">
                    <span
                      class="dot dot-circle secondary"
                      style="width: 6px; height: 6px"
                    />
                  </p>
                  <p class="opacity-50 mr-2" style="font-size: 10px">
                    {{ `${item.function ? item.function : "---"}` }}
                    {{ item.sharedAgreement }}
                  </p>
                  <p
                    class="ml-2 opacity-50 text-romanSilver"
                    v-if="item.sharedAgreement === ''"
                    style="font-size: 10px"
                  >
                    Not shared yet
                  </p>
                  <p
                    class="ml-2 opacity-50 text-carrotOrange"
                    v-if="
                      item.sharedAgreement === 'submitted' && !item.approved
                    "
                    style="font-size: 10px"
                  >
                    Submitted and Pending
                  </p>
                  <p
                    class="ml-2 opacity-50 text-mediumSeaGreen"
                    v-if="item.sharedAgreement === 'submitted' && item.approved"
                    style="font-size: 10px"
                  >
                    Submitted and Approved
                  </p>
                  <p
                    class="ml-2 opacity-50 text-carrotOrange cursor-pointer underline"
                    v-if="
                      item.sharedAgreement === 'shared' ||
                      item.sharedAgreement === 'pending'
                    "
                    style="font-size: 10px"
                    @click="viewSharedAgreement(item.agreementId, item.id)"
                  >
                    Shared with you
                  </p>
                </div>
                <p
                  class="opacity-50 uppercase text-carrotOrange"
                  v-if="item.text !== ''"
                  style="font-size: 10px"
                >
                  {{ item.text }}
                </p>
              </div>
              <checkbox
                checkbox-size="height:16px; width:16px; margin:4px 7px 8px 8px;"
                label-style="color:#333333; "
                :class="
                  item.approved ||
                  item.text === 'Selected Template not applicable to employee'
                    ? `opacity-30 mr-6`
                    : 'mr-6'
                "
                :value="item.selected"
                v-model="item.selected"
                :disabled="
                  item.sharedAgreement === 'submitted' ||
                  item.text === 'Selected Template not applicable to employee'
                    ? true
                    : false
                "
              />
            </div>
          </div>
        </div>
      </RightSideBar>

      <RightSideBar
        v-if="viewEmployeesModal"
        close-button
        @close="viewEmployeesModal = false"
        :button-class="`bg-dynamicBackBtn text-white`"
      >
        <template v-slot:title>
          <div class="w-full flex">
            <p class="text-darkPurple mt-3">Employee Details</p>
          </div>
        </template>

        <div style="height: 100%" class="mb-4 mt-10" v-if="loadingEmp">
          <loader size="xxs" :loader-image="false" />
        </div>

        <div class="flex items-center mb-3" v-else>
          <div>
            <img
              class="mr-3"
              :src="viewEmployee.photo"
              v-if="viewEmployee.photo"
              alt="user photo"
              style="height: 80px; width: 80px; border-radius: 5px"
            />
            <div
              style="height: 80px; width: 80px; border-radius: 5px"
              class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-7"
              v-else
            >
              {{ $getInitials(`${viewEmployee.fname} ${viewEmployee.lname}`) }}
            </div>
          </div>
          <div class="flex flex-col">
            <span class="text-lg font-bold">
              {{ `${viewEmployee.fname} ${viewEmployee.lname}` }}
            </span>
            <span class="font-semibold text-sm">
              {{
                viewEmployee.employeeId === null
                  ? `---- `
                  : viewEmployee.employeeId
              }}
            </span>
            <span class="text-romanSilver uppercase" style="font-size: 10px">
              Since {{ formatEmployeeDate(viewEmployee.employmentDate) }}
            </span>
          </div>
        </div>

        <div v-if="!loadingEmp">
          <div class="border border-dashed mb-4" />

          <div class="w-full my-4">
            <span class="text-base font-semibold"> Work Details </span>
          </div>
          <div class="flex flex-col w-full">
            <div class="w-full flex">
              <card class="flex p-3 mb-4 w-1/2 mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="icon-suitcase" size="xs" />
                    <span class="ml-1 text-chartPurple text-xs">
                      Designation
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ viewEmployee.userDesignation }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 mb-4 w-1/2 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="icon-department" size="xs" />
                    <span class="ml-1 text-carrotOrange text-xs">
                      Function
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ viewEmployee.employeeFunction }}
                  </div>
                </div>
              </card>
            </div>
            <div class="w-full flex">
              <card class="flex p-3 mb-4 w-1/2 mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="levels" color="#FFFFFF" size="xs" />
                    <span class="ml-1 text-desire text-xs"> Level </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ viewEmployee.level }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 w-1/2 mb-4 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="line-manager" color="#FFFFFF" size="xs" />
                    <span class="ml-1 text-blueCrayola text-xs">
                      Line Manager
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ viewEmployee.reportingTo }}
                  </div>
                </div>
              </card>
            </div>
          </div>

          <div class="w-full flex">
            <card class="flex p-3 mb-4 w-1/2 mr-3 flex-grow">
              <div class="flex flex-col">
                <div class="flex">
                  <icon icon-name="icon-manager" color="#878E99" size="xs" />
                  <span class="ml-1 text-mediumSeaGreen text-xs">
                    Supervisory Function
                  </span>
                </div>
                <div
                  class="text-xs ml-5 mt-1 font-semibold uppercase sd"
                  v-if="viewEmployee.supervisory"
                >
                  {{ viewEmployee.userDesignation }}
                </div>
                <div class="text-xs ml-5 mt-1 font-semibold uppercase" v-else>
                  ----
                </div>
              </div>
            </card>
            <card class="flex p-3 w-1/2 mb-4 flex-grow">
              <div class="flex flex-col">
                <div class="flex">
                  <icon
                    icon-name="icon-display-grid"
                    color="#878E99"
                    size="xs"
                  />
                  <span class="ml-1 text-blueCrayola text-xs">
                    Employment Type
                  </span>
                </div>
                <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                  {{ viewEmployee.employmentType }}
                </div>
              </div>
            </card>
          </div>

          <div class="border border-dashed w-full" />

          <div class="w-full my-4">
            <span class="text-base font-semibold"> Others </span>
          </div>
          <div class="flex flex-col w-full">
            <div class="w-full flex">
              <card class="flex p-3 mb-4 mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="message" size="xms" />
                    <span class="ml-2 text-blueCrayola text-xs"> Email </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold">
                    {{ viewEmployee.email }}
                  </div>
                </div>
              </card>
              <card class="flex p-3 mb-4">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="icon-phone" size="xs" />
                    <span class="ml-1 text-carrotOrange text-xs"> Phone </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ viewEmployee.phoneNo }}
                  </div>
                </div>
              </card>
            </div>

            <div class="w-full flex">
              <card class="flex p-3 mb-4 mr-3 flex-grow">
                <div class="flex flex-col">
                  <div class="flex">
                    <icon icon-name="map-pin" size="xms" />
                    <span class="ml-2 text-dome text-xs">
                      Office Location
                    </span>
                  </div>
                  <div class="text-xs ml-5 mt-1 font-semibold uppercase">
                    {{ viewEmployee.office }}
                  </div>
                </div>
              </card>
            </div>
          </div>
        </div>
      </RightSideBar>

      <RightSideBar
        v-if="warningEmployees"
        :close-button="sharedEmployees.length === 0"
        @close="warningEmployees = false"
        submit="Proceed"
        @submit="submitAction"
        :button-class="`bg-dynamicBackBtn text-white`"
      >
        <template v-slot:title>
          <div class="w-full flex">
            <back-button
              label=""
              @onClick="warningEmployees = false"
              variant="secondary"
            />
            <p class="text-darkPurple mt-3">Add Employees</p>
            <span class="ml-5 mt-4"
              ><svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.3334 14.3212L10.3884 1.54119C10.2502 1.28648 10.0457 1.07382 9.7967 0.92563C9.54765 0.77744 9.26322 0.699219 8.97342 0.699219C8.68362 0.699219 8.39919 0.77744 8.15014 0.92563C7.9011 1.07382 7.69667 1.28648 7.55842 1.54119L0.618419 14.3212C0.481181 14.5669 0.410761 14.8443 0.414181 15.1258C0.417602 15.4072 0.494743 15.6828 0.637913 15.9252C0.781083 16.1675 0.985279 16.3681 1.23014 16.5069C1.475 16.6456 1.75196 16.7178 2.03342 16.7162H15.9184C16.197 16.7159 16.4708 16.6433 16.7129 16.5056C16.9551 16.3678 17.1573 16.1696 17.2999 15.9302C17.4425 15.6909 17.5205 15.4187 17.5264 15.1401C17.5323 14.8616 17.4658 14.5863 17.3334 14.3412V14.3212ZM8.33342 4.71619C8.33342 4.53053 8.40717 4.35249 8.53844 4.22121C8.66972 4.08994 8.84777 4.01619 9.03342 4.01619C9.21907 4.01619 9.39712 4.08994 9.52839 4.22121C9.65967 4.35249 9.73342 4.53053 9.73342 4.71619V10.7162C9.73342 10.9018 9.65967 11.0799 9.52839 11.2112C9.39712 11.3424 9.21907 11.4162 9.03342 11.4162C8.84777 11.4162 8.66972 11.3424 8.53844 11.2112C8.40717 11.0799 8.33342 10.9018 8.33342 10.7162V4.71619ZM9.03342 14.6412C8.85542 14.6412 8.68141 14.5884 8.53341 14.4895C8.3854 14.3906 8.27005 14.2501 8.20193 14.0856C8.13381 13.9211 8.11599 13.7402 8.15071 13.5656C8.18544 13.391 8.27116 13.2307 8.39702 13.1048C8.52289 12.9789 8.68326 12.8932 8.85784 12.8585C9.03242 12.8238 9.21338 12.8416 9.37783 12.9097C9.54229 12.9778 9.68285 13.0932 9.78174 13.2412C9.88064 13.3892 9.93342 13.5632 9.93342 13.7412C9.93342 13.8594 9.91014 13.9764 9.86491 14.0856C9.81968 14.1948 9.75339 14.294 9.66982 14.3776C9.58624 14.4612 9.48703 14.5274 9.37783 14.5727C9.26864 14.6179 9.15161 14.6412 9.03342 14.6412Z"
                  fill="#E99323"
                />
              </svg>
            </span>
          </div>
        </template>

        <div class="flex flex-col mb-3">
          <div class="flex flex-col">
            <p class="font-semibold text-base">
              One or more of selected employees have already share an agreement
              draft with you
            </p>
            <p class="mt-3">
              Choose how you want to proceed from here. You can choose to remove
              them from the selection, override their shared drafts or allow
              them to choose what they want on their end.
            </p>
            <div class="w-full border border-dashed my-4" />
          </div>
          <p class="text-base font-semibold">Affected Employees</p>

          <div
            v-for="(item, index) in sharedEmployees"
            :key="index"
            class="flex flex-col py-4 my-2 userContainer"
          >
            <div class="flex items-center">
              <div>
                <img
                  class="mr-3"
                  :src="item.photo"
                  v-if="item.photo"
                  alt="user photo"
                  style="height: 35px; width: 35px; border-radius: 5px"
                />
                <div
                  style="height: 35px; width: 35px; border-radius: 5px"
                  class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                  v-else
                >
                  {{ $getInitials(`${item.fname} ${item.lname}`) }}
                </div>
              </div>
              <div class="flex flex-col">
                <p>
                  {{ `${item.fname} ${item.lname}` }}
                </p>
                <div
                  class="text-xs flex font-semibold w- text-romanSilver uppercase"
                >
                  <p class="opacity-50 mr-2" style="font-size: 10px">
                    {{
                      `${item.userDesignation ? item.userDesignation : "---"}`
                    }}
                  </p>
                </div>
              </div>
              <div
                class="flex w-auto cursor-pointer ml-5 -mt-1"
                @click="deleteSharedAgreement(item.sharedId, index)"
              >
                <icon icon-name="icon-trash" size="xsm" />
              </div>
            </div>
          </div>

          <div
            class="w-full border border-dashed"
            style="border-width: 0.5px"
          />

          <div class="w-full flex flex-col mt-4">
            <p class="text-base font-semibold">What would you like to do?</p>

            <radio-button
              :options="category"
              col-span="col-span-12"
              class="mt-4 ml-2 text-darkPurple"
              row-gap="gap-y-5"
              space-between="mr-2"
              v-model="agreementAction"
            />
          </div>
        </div>
      </RightSideBar>

      <import
      :open-modal="openImport"
      :payload="payload"
      :selected-template="payload.templateId"
      :selected-employees="selectedEmployees"
      @close="closeImport"
    />
    </div>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Datepicker from "vuejs-datepicker";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Badge from "@scelloo/cloudenly-ui/src/components/badge";
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import Button from "@/components/Button";
import Checkbox from "@/components/Checkbox";
import ScrollContainer from "@/components/ScrollContainer";
import RightSideBar from "@/components/RightSideBar";
import Accordion from "@/components/Accordion";
import RadioButton from "@/components/RadioButton";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import Import from "./import";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    ValidationObserver,
    BackButton,
    Breadcrumb,
    RadioButton,
    Icon,
    CText,
    Card,
    Button,
    RightSideBar,
    SearchInput,
    Checkbox,
    Badge,
    CSelect,
    Accordion,
    Table,
    ScrollContainer,
    Datepicker,
    Import
  },
  data() {
    return {
      loading: true,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Agreement",
          href: "Performance Agreement",
          id: "Agreement",
        },
        {
          disabled: false,
          text: "Shared With Me",
          href: "Shared With Me",
          id: "Shared With Me",
        },
        {
          disabled: false,
          text: "New Agreement",
          id: "New Agreement",
        },
      ],
      illustration: "illustration",
      category: [
        {
          name: "Allow employees decide what to do",
          radioName: "descision",
          value: "allow",
        },
        {
          name: "Override previous shared agreements",
          radioName: "descision",
          value: "override",
        },
      ],
      allocatedWeight: 0,
      templateKPATypes: [],
      weightRules: [
        "required",
        {
          name: "weight",
          rule: (w) => this.validateWeight(w),
        },
      ],
      KPAHeader: [
        { title: "•", value: "state" },
        { title: "KPI", value: "name" },
        { title: "Objective", value: "objective" },
        { title: "Criteria", value: "kpis" },
        { title: "Target Date", value: "target_date" },
        { title: "Weight (%)", value: "weight" },
      ],
      loadingBar: true,
      agreementAction: "",
      loadingEmp: true,
      moreIcon: "more_icon",
      doccycle: "doc-cycle",
      calendarIcon: "calendar",
      reviewIcon: "reviewicon",
      starreviewIcon: "star-review",
      iconInfo: "icon-info",
      iconPlus: "icon-plus",
      iconCopy: "icon-copy",
      iconEdit: "icon-edit",
      iconChevron: "chevronRight",
      disableBtn: true,
      watchOthers: false,
      startSearch: "",
      addEmployeesModal: false,
      periodError: false,
      employees: [],
      selectedEmployees: [],
      viewEmployee: [],
      viewEmployeesModal: false,
      previousGoalModal: false,
      Ratings: [],
      kpaWeights: [],
      kpaName: [],
      totalWeight: "",
      templateIds: [],
      ratingsIds: [],
      templateOptions: [],
      prevAgreementKPA: [],
      selectedTemplate: "",
      cycleStartPeriod: "",
      cycleEndPeriod: "",
      cycleDescription: "",
      cycleTitle: "",
      appraisal_cycles: [],
      functions: [],
      kpaData: [],
      goalsKPA: [],
      validateKpa: "",
      sharedEmployees: [],
      setNewAgreement: false,
      agreementKPA: [],
      managerLock: false,
      applicableEmployees: [],
      warningEmployees: false,
      payload: {
        orgId: "",
        goalId: "",
        templateId: "",
        title: "",
        year: "",
        status: "draft",
        share_for_review: false,
        created_for: [],
        created_by: "",
        kpa_types: [],
      },
      importPayload: {},
      openImport: false,

    };
  },

  watch: {

    selectedTemplate(value) {
      this.selectedEmployees = [];

      this.employees.map((employee) => {
        // eslint-disable-next-line no-param-reassign
        employee.disabled = false;
        // eslint-disable-next-line no-param-reassign
        employee.text = "";

        if (!this.setNewAgreement) {
          // eslint-disable-next-line no-param-reassign
          employee.selected = false;
        }

        return {};
      });

      this.setApplicableEmployees(value);

      this.$_getOneTemplate(value).then((response) => {
        this.$_getKPAWeights(value).then((res) => {
          const kpaData = res.data.data;

          this.kpaWeights = kpaData;

          let sum = 0;

          this.kpaWeights.forEach((kpa) => {
            sum += kpa.totalKpiWeight;
          });

          this.totalWeight = sum;
        });

        this.disableBtn = false;

        this.payload.templateId = value;

        this.cycleDescription =
          response.data.PerformanceTemplate.agreement_description;

        this.cycleTitle = response.data.PerformanceTemplate.agreement_title;

        this.payload.title = this.cycleTitle;
        this.payload.year = new Date(response.data.PerformanceTemplate.year, 0);

        if (this.agreementKPA.length === 0) {
          if (this.prevAgreementKPA.length === 0) {
            this.goalsKPA.filter((goalKPA) => {
              if (goalKPA.id === value) {
                this.templateKPATypes = goalKPA.kpa_types;

                this.templateKPATypes.forEach((kpaType) => {
                  const kpaData = kpaType;

                  const newKPAs = [];

                  if (kpaType.kpas.length > 0) {
                    kpaType.kpas.map((kpasData) => {
                      newKPAs.push({
                        state: true,
                        isLocked: kpasData.isLocked,
                        name: kpasData.name,
                        objective: kpasData.objective,
                        kpis: this.getCriteriaData(kpasData.kpi),
                        target_date: this.resetDate(kpasData.target_date),
                        weight: kpasData.weight,
                      });
                      return {};
                    });

                    kpaData.kpas = newKPAs;
                  } else {
                    kpaType.kpas.push({
                      state: true,
                      isLocked: false,
                      name: null,
                      objective: null,
                      kpis: this.getCriteriaData(),
                      target_date: null,
                      weight: "",
                    });
                  }
                });
              }

              return {};
            });
          } else {
            this.setPrevAgreementData(this.prevAgreementKPA);
          }
        } else {
          this.setAgreementData(this.agreementKPA);
        }
      });
    },

  },

  methods: {
    setAgreementData(agreementKPA) {
      agreementKPA.map((detail) => {
        if (this.kpaName.includes(detail.kpa_type.id)) {
          this.templateKPATypes.filter((kpaType) => {
            if (kpaType.id === detail.kpa_type.id) {
              kpaType.kpas.push({
                state: true,
                isLocked: detail.isLocked,
                name: detail.name,
                objective: detail.objective,
                kpaId: detail.id,
                kpis: this.getCriteriaData(detail.kpis_employee),
                target_date: this.resetDate(detail.targetDate),
                weight: detail.weight,
              });
            }
            return {};
          });
        } else {
          this.templateKPATypes.push({
            id: detail.kpa_type.id,
            name: detail.kpa_type.name,
            kpas: [
              {
                state: true,
                isLocked: detail.isLocked,
                name: detail.name,
                objective: detail.objective,
                kpaId: detail.id,
                kpis: this.getCriteriaData(detail.kpis_employee),
                target_date: this.resetDate(detail.targetDate),
                weight: detail.weight,
              },
            ],
          });

          this.kpaName.push(detail.kpa_type.id);
        }
        return {};
      });

      setTimeout(() => {
        window.localStorage.removeItem("adoptAgreement");
      }, 2000);
    },


     impExpOptSelected() {
      if (this.selectedEmployees.length < 1) {
        this.$toasted.error("Please add at least one employee.", {
          duration: 5000,
        });
      } else {
        this.openImport = true;

      }
    },
    closeImport() {
      this.openImport = false;
    },

    setPrevAgreementData(prevAgreementKPA) {
      this.templateKPATypes = prevAgreementKPA;
      setTimeout(() => {
        window.localStorage.removeItem("createAgreement");
        this.setNewAgreement = false;
      }, 4000);
    },

    viewSharedAgreement(agreementId, userId) {
      this.employees.filter((employee) => {
        if (employee.selected) {
          this.selectedEmployees.push(employee.id);
        }
        return {};
      });

      const agreementStorage = {
        templateId: this.payload.templateId,
        year: this.payload.year,
        addModal: true,
        employeesList: this.employees,
        agreementKPA: this.templateKPATypes,
      };

      window.localStorage.setItem(
        "createAgreement",
        JSON.stringify(agreementStorage)
      );

      this.$router.push({
        name: "AdoptSharedAgreement",
        params: { id: agreementId, user: userId },
      });
    },

    periodSelect() {
      this.periodError = false;
    },

    getKPAWeight(kpaTypes, kpaTypesId) {
      let sum = 0;

      if (kpaTypes.id === kpaTypesId) {
        kpaTypes.kpas.forEach((kpi) => {
          sum += Number(kpi.weight);
        });

        this.kpaWeights.filter((kpa) => {
          if (kpa.id === kpaTypesId) {
            // eslint-disable-next-line no-param-reassign
            kpa.weight = sum;
          }
          return {};
        });
      }

      return sum || 0;
    },

    getTotalWeight(kpaTypes, kpaTypesId) {
      let sum = 0;
      this.kpaWeights.filter((kpa) => {
        if (kpa.id === kpaTypesId) {
          sum = kpa.totalKpiWeight;
        }
        return {};
      });

      return sum;
    },

    addEmployees() {
      if (this.selectedTemplate === "") {
        this.$toasted.error("Select a template to proceed", { duration: 5000 });
      } else {
        this.addEmployeesModal = true;
      }
    },

    lockKPA(isLocked, data) {
      if (this.managerLock) {
        // eslint-disable-next-line no-param-reassign
        data.isLocked = !isLocked;
      } else {
        this.$toasted.error(
          "You do not have the privilege to lock or unlock an agreement",
          { duration: 5000 }
        );
      }
    },

    resetDate(value) {
      if (value) {
        const dateValue = value;

        const split = dateValue.split("T00:00:00.000Z");

        return `${split[0]}T13:00:00.000Z`;
      }
      return "";
    },

    validateWeight(weight) {
      let sum = 0;
      if (weight < 0) {
        return "Value must be greater than or equal to 0";
      }

      this.templateKPATypes.forEach((kpaType) => {
        kpaType.kpas.map((kpi) => {
          sum += Number(kpi.weight);
          return {};
        });
        return {};
      });

      if (sum > 100) {
        return "Total allocated weight for all KPA's cannot be greater than 100%";
      }

      this.allocatedWeight = sum;

      return true;
    },

    handleOpen(state) {
      if (state) {
        this.disableBtn = false;
      } else {
        this.disableBtn = true;
      }
    },

    deleteKPA(index, kpaTypes) {
      // let sum = 0;
      if (kpaTypes.kpas.length > 1) {
        kpaTypes.kpas.splice(index, 1);
        this.allocatedWeight = this.sumKpaWeights(this.templateKPATypes);
      } else {
        this.$toasted.error(
          "Cannot delete ALL KPIs of a KPA Type, you can edit KPI values instead",
          { duration: 5000 }
        );
      }
    },

    sumKpaWeights(data) {
      return data.reduce((totalWeight, item) => {
        // Sum up the weights of the KPAs
        const kpaWeightSum = item.kpas.reduce((sum, kpa) => {
          // Ensure weight is treated as a number
          const weight = Number(kpa.weight) || 0;
          return sum + weight;
        }, 0);
        return totalWeight + kpaWeightSum;
      }, 0);
    },

    formatEmployeeDate(value) {
      let initialDate = "";

      if (value) {
        initialDate = new Date(value).toISOString().replace(/.\d+Z$/g, "");

        return this.$DATEFORMAT(new Date(initialDate), "MMMM dd, yyyy");
      }

      return "";
    },

    submit(status) {
      this.kpaData = [];

      this.templateKPATypes.forEach((kpaType) => {
        const newKPAs = kpaType.kpas.map(({ state, kpaId, ...rest }) => ({
          ...rest,
        }));

        newKPAs.map((kpi) => {
          const kpiData = kpi;
          kpiData.target_date = this.formatDate(kpiData.target_date, "arrange");

          return {};
        });

        const validation = newKPAs.every(
          (item) => item.name || item.objective || item.weight
        );

        if (validation) {
          this.kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: newKPAs,
          });
        } else {
          this.kpaData.push({
            kpa_types_id: kpaType.id,
            kpas: [],
          });
        }

        return {};
      });

      this.payload.kpa_types = this.kpaData;
      this.payload.orgId = this.$orgId;

      this.selectedEmployees.map((selected) => {
        this.payload.created_for.push(selected.id);
        return {};
      });

      this.payload.created_by = this.$AuthUser.id;
      this.payload.senderId = this.$AuthUser.id;

      if (!status) {
        this.payload.status = "active";
        this.payload.share_for_review = true;
        this.payload.agreement_status = "shared";
      }

      if (this.payload.year !== "") {
        this.payload.year = new Date(this.payload.year).getFullYear();

        this.payload.description = this.payload.title;
        this.disableBtn = true;

        this.$_createManagerAgreement(this.payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            setTimeout(() => {
              window.localStorage.removeItem("createAgreement");
            }, 2000);

            this.$router.push({
              name: "EssMSSPerformance",
              query: { activeTab: "Direct Report Agreements" },
            });
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message, {
              duration: 5000,
            });
          });
      } else if (this.payload.year === "") {
        this.periodError = true;
        this.payload.status = "draft";
      }
    },

    createNewKPA(kpa) {
      const arrayObj = kpa.kpas[0];
      let isEmpty = true;

      if (
        arrayObj.name !== "" &&
        arrayObj.objective !== "" &&
        arrayObj.weight !== ""
      ) {
        // iterate over criteria array
        arrayObj.kpis.forEach((v) => {
          if (Object.keys(v).every((k) => v[k] !== "")) {
            isEmpty = false;
          }
        });
      }

      this.addKPATable(kpa.id, isEmpty);
    },

    addKPATable(kpaId, valid) {
      if (!valid) {
        if (this.allocatedWeight < 100) {
          this.templateKPATypes.forEach((kpaType) => {
            if (kpaType.id === kpaId) {
              kpaType.kpas.push({
                state: true,
                isLocked: false,
                name: null,
                objective: null,
                kpis: this.getCriteriaData(),
                target_date: "",
                weight: "",
              });
            }
            return {};
          });
        } else {
          this.$toasted.error(
            "Total allocated weight for all KPA's cannot be greater than 100%",
            { duration: 5000 }
          );
        }
      }
    },

    getCriteriaData(kpis) {
      const criteriaData = [];
      const sortedRatings = this.Ratings.sort((a, b) => a.rating - b.rating);

      this.Ratings = sortedRatings;

      if (kpis) {
        this.Ratings.map((rating, index) => {
          criteriaData.push({
            id: kpis[index].id,
            performanceRatingId: rating.id,
            criteria: kpis[index].criteria,
          });
          return {};
        });
      } else {
        this.Ratings.map((rating) => {
          criteriaData.push({
            performanceRatingId: rating.id,
            criteria: "",
          });
          return {};
        });
      }

      return criteriaData;
    },

    getReportingEmployees() {
      this.loadingBar = true;
      const currentYear = new Date().getFullYear();
      this.$_getManagerDirectReportStatus(this.$AuthUser.id, currentYear).then(
        (response) => {
          this.loadingBar = false;
          response.data.sharedAgreements.map((employee) => {
            this.employees.push({
              id: employee.user.userId,
              fname: employee.user.fname,
              lname: employee.user.lname,
              userDesignation: employee.user.designation,
              function: employee.user.function,
              photo: employee.user.photo,
              selected: false,
              sharedAgreement: employee.agreement
                ? employee.agreement.agreement_status
                : "",
              approved: employee.agreement
                ? employee.agreement.approveAndSubmit
                : "",
              disabled: false,
              agreementId: employee.agreement ? employee.agreement.id : "",
              text: "",
            });

            this.applicableEmployees.push(employee.user.userId);
            return {};
          });
        }
      );
    },

    getTemplates() {
      let templatesArr = [];

      this.$_getPerfomanceTemplate("").then((response) => {
        templatesArr = response.data.PerformanceTemplate;

        templatesArr.filter((template) => {
          if (template.status !== "draft") {
            const found = this.templateIds.some(
              (el) => el.templateId === template.id
            );

            if (found) {
              this.templateIds.filter(() => {
                  this.templateOptions.push({
                    id: template.id,
                    name: template.title,
                  });
                return {};
              });
            }
          }
          return {};
        });

        this.loading = false;
      });
    },

    setApplicableEmployees(templateId) {
      this.templateIds.filter((templateObj) => {
        if (templateObj.templateId === templateId) {
          this.employees.map((employee) => {
            if (templateObj.employees.includes(employee.id)) {
              // eslint-disable-next-line no-param-reassign
              employee.disabled = false;
              // eslint-disable-next-line no-param-reassign
              employee.text = "";
            } else {
              // eslint-disable-next-line no-param-reassign
              employee.disabled = true;
              // eslint-disable-next-line no-param-reassign
              employee.text = "Selected Template not applicable to employee";
              // eslint-disable-next-line no-param-reassign
              // employee.sharedAgreement = "";
            }
            return {};
          });
        }
        return {};
      });
    },

    getGoals() {
      this.$_getOrganizationGoal("").then((response) => {
        const goalData = response.data.goals;
        const ids = [];
        const idNames = [];

        goalData.forEach((goals) => {
          this.payload.goalId = goals.goal.id;
          if (idNames.includes(goals.goal.goal_performance_templates.id)) {
            ids.filter((idData) => {
              if (idData === goals.goal.goal_performance_templates.id) {
                idData.employees.push(goals.appliedEmployees);
              }
              return {};
            });
          } else {
            ids.push({
              templateId: goals.goal.goal_performance_templates.id,
              employees: goals.appliedEmployees,
            });
          }

          idNames.push(goals.goal.goal_performance_templates.id);
          this.goalsKPA.push(goals.goal.goal_performance_templates);
        });

        this.templateIds = ids;
        this.getTemplates();
      });
    },

    checkValue(value, item) {
      if (value) {
        this.$_checkEmployeeSharedAgreement(item.id).then((response) => {
          // eslint-disable-next-line no-param-reassign
          item.sharedAgreement = response.data.status.submitted;

          if (item.sharedAgreement === `Shared with You`) {
            // eslint-disable-next-line no-param-reassign
            item.sharedId = response.data.sharedAgreement.id;
            // eslint-disable-next-line no-param-reassign
            item.agreementId =
              response.data.sharedAgreement.employeePerformanceAgreementId;
          }
        });
      } else {
        // eslint-disable-next-line no-param-reassign
        item.sharedAgreement = "";
      }
    },

    deleteSharedAgreement(sharedId, index) {
      this.$_deleteSharedAgreement(sharedId).then((response) => {
        this.$toasted.success(response.data.message, { duration: 5000 });
        this.sharedEmployees.splice(index, 1);
      });
    },

    submitAction() {
      let payload;
      this.sharedEmployees.map((employees) => {
        payload = {
          sharedId: employees.sharedId,
          agreementId: employees.agreementId,
        };

        // eslint-disable-next-line no-param-reassign
        employees.selected = true;
        return {};
      });

      if (this.agreementAction === "allow") {
        this.$_allowEmployeeAction(payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.warningEmployees = false;
            this.sharedEmployees = [];
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message, {
              duration: 5000,
            });
          });
      } else {
        this.$_overideEmployeeAgreement(payload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 5000 });
            this.warningEmployees = false;
            this.sharedEmployees = [];
          })
          .catch((error) => {
            this.$toasted.error(error.response.data.message, {
              duration: 5000,
            });
          });
      }
    },

    resetEmployeeSubmit() {
      this.warningEmployees = true;
    },

    employeeSubmit() {
      this.selectedEmployees = [];
      this.employees.filter((employee) => {
        if (employee.selected) {
          this.selectedEmployees.push(employee);
        }
        return {};
      });

      this.addEmployeesModal = false;
    },

    removeEmployee(index, employeeData) {
      this.selectedEmployees.splice(index, 1);

      this.employees.filter((employee) => {
        if (employee.id === employeeData.id) {
          // eslint-disable-next-line no-param-reassign
          employee.selected = false;
        }
        return {};
      });
    },

    getFunctions() {
      this.functions = [];
      this.$_getFunctionKpis("").then((response) => {
        response.data.functions.forEach((f) => {
          this.functions.push({
            id: f.id,
            name: f.name,
          });
        });
      });
    },

    viewEmployeeDetails(employeeId) {
      const viewEmployee = [];
      const payload = { id: employeeId };
      this.viewEmployeesModal = true;
      const employeeObj = [];
      this.loadingEmp = true;

      this.$_getOneEmployeeAll(payload).then((response) => {
        employeeObj.push({
          id: response.data.id,
          fname: response.data.fname,
          lname: response.data.lname,
          photo: response.data.photo,
          employeeId: response.data.employeeId,
          userDesignation: response.data.userDesignation.name,
          employmentDate: response.data.employmentDate,
          level: response.data.userLevel.name,
          reportingTo: `${response.data.userReportingTo.fname} ${response.data.userReportingTo.lname}`,
          employmentType: response.data.employmentType,
          supervisory: response.data.userDesignation.isSupervisory,
          email: response.data.email,
          phoneNo: response.data.phoneNo,
          office: response.data.employeeOffice.name,
        });

        this.functions.filter((f) => {
          if (f.id === response.data.functionId) {
            employeeObj.push({
              employeeFunction: f.name,
            });
          }
          return {};
        });

        viewEmployee.push(
          employeeObj.reduce((r, i) => Object.assign(r, i), {})
        );

        this.viewEmployee = Object.assign({}, ...viewEmployee);

        this.loadingEmp = false;
      });
    },

    getAppraisalCycles() {
      this.$_getAppraisalCycle(this.agreementQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;

        appraisal.map((appraisalCycles) => {
          const startDate = this.setDates(
            `${appraisalCycles.cycle_start_date}`
          );

          const endDate = this.setDates(`${appraisalCycles.cycle_end_date}`);

          this.cycleStartPeriod = startDate.toISOString();

          this.cycleEndPeriod = endDate.toISOString();

          appraisalCycles.cycles.forEach((cycle) => {
            const startCycleDate = this.setDates(`${cycle.appraisal_starts}`);

            const endCycleDate = this.setDates(`${cycle.appraisal_ends}`);

            this.appraisal_cycles.push({
              name: cycle.name,
              appraisal_starts: startCycleDate.toISOString(),
              appraisal_ends: endCycleDate.toISOString(),
            });
          });

          return {};
        });
      });
    },

    getSettings() {
      this.$_getPerformanceGeneralSetting().then((response) => {
        const settings = response.data.PerformanceSettings;

        this.managerLock = settings[0].managerCanLockAgreement;
      });
    },

    setEmployeesData(employeesList) {
      employeesList.map((employee) => {
        this.employees.push({
          id: employee.id,
          fname: employee.fname,
          lname: employee.lname,
          userDesignation: employee.userDesignation,
          function: employee.function,
          photo: employee.photo,
          selected: employee.selected,
          sharedAgreement: employee.sharedAgreement,
          sharedId: employee.sharedId,
          agreementId: employee.agreementId,
          approved: employee.approved,
          disabled: false,
          text: "",
        });
        return {};
      });
    },
  },

  mounted() {
    this.$_getPerfomanceRating().then((response) => {
      this.Ratings = response.data.PerformanceRatings;
    });
    this.getGoals();
    this.getFunctions();
    this.getAppraisalCycles();
    this.getSettings();

    const adoptedAgreement = JSON.parse(
      window.localStorage.getItem("adoptAgreement")
    );

    if (adoptedAgreement !== null) {
      this.agreementKPA = adoptedAgreement.employee_agreement_kpa;
      this.selectedTemplate = adoptedAgreement.templateId;
      this.payload.year = adoptedAgreement.year;
    }

    const newAgreement = JSON.parse(
      window.localStorage.getItem("createAgreement")
    );

    if (newAgreement !== null) {
      this.setNewAgreement = true;
      this.prevAgreementKPA = newAgreement.agreementKPA;
      this.selectedTemplate = newAgreement.templateId;
      this.payload.year = newAgreement.year;
      this.addEmployeesModal = newAgreement.addModal;
      this.loadingBar = false;

      this.setEmployeesData(newAgreement.employeesList);
    }

    if (newAgreement === null) {
      this.getReportingEmployees();
    }
  },
};
</script>

<style>
.bg-disabled {
  background-color: #eeeeee;
  border-color: transparent;
}

.cyc-text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
}

.label label {
  display: block;
  font-size: 10px;
}

.inner-text {
  font-size: 12px;
  line-height: 1.9rem;
  font-weight: 600;
  text-align: center;
  color: #878e99;
  text-transform: uppercase;
}

.cycle {
  border: 1px dashed #878e99;
  border-radius: 5px;
  padding: 12px;
}

.cycle__text {
  font-size: 12px;
  font-weight: 900;
  line-height: 1.3rem;
  color: #878e99;
  letter-spacing: 0.035em;
  height: 17px;
}

.fcard {
  padding: 12px;
  height: 58px;
  margin-top: 18px;
  line-height: 16.8px;
  text-transform: uppercase;
}

.fcard__icon {
  margin-right: 7.5px;
}

.csm {
  width: 12px;
  height: 12px;
}

.fcard__header {
  font-weight: 900;
  font-size: 12px;
  margin-top: -2px;
  letter-spacing: 0.035em;
}

.fcard__text {
  font-weight: 600;
  font-size: 14px;
  color: #333333;
  margin-right: 7.5px;
  letter-spacing: 0.015em;
}

.allocate {
  width: 320px;
  border-radius: 5px;
  padding: 8px;
  background-color: #f7f7ff;
  font-weight: 900;
  font-size: 12px;
  letter-spacing: 0.035em;
}

.allocate__text {
  line-height: 1.4rem;
  text-transform: uppercase;
  color: #321c3b;
}

.allocate__value {
  line-height: 17px;
  color: #ea3c53;
  margin: 3px 10px;
}

.line {
  width: 24px;
  height: 0px;
  transform: rotate(90deg);
  margin-left: 6px;
  margin-right: 6px;
}

.line--dashed {
  border: 0.5px dashed #878e99;
}

.line--straight {
  border: 1px solid #878e99;
}

.kpaplus {
  width: 180px;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
}

.kpaplus__text {
  font-weight: 900;
  font-size: 13px;
  line-height: 17px;
  letter-spacing: 0.035em;
  margin: 3px 10px;
  color: #f15a29;
}

.ksm {
  margin-left: 11px;
  color: #ea3c53;
  width: 14px;
  height: 14px;
  margin-top: 4px;
}

.kpalist__text {
  font-size: 18px;
  font-weight: bold;
  line-height: 120%;
  letter-spacing: 0.015em;
  color: #321c3b;
  width: 170px;
  height: 22px;
}

.kpalisticon {
  width: 18px;
  height: 18px;
}

.button-layout {
  width: 270px;
  height: 44px;
  margin-top: 29px;
}

.btn-disabled {
  background-color: #eeeeee;
  font-size: 16px;
  cursor: disabled;
}

details > summary {
  display: inline;
  list-style: none;
}

.icon-Size {
  width: 200px;
  height: 200px;
}
.illu-text {
  text-align: center;
  font-weight: 400;
  line-height: 19px;
  font-size: 16px;
  letter-spacing: 0.015em;
}
</style>
